import { statesOptions } from '@/common/formulate/form-schemas'

export const values = {
  billing_info: [
    { payment_method: 'card', document_type: 'cpf', country_id: 33, country_code: 'BR' }
  ]
}

export const schema = [
  {
    type: 'group',
    repeatable: false,
    name: 'billing_info',
    children: [
      {
        component: 'div',
        class: 'form-row',
        children: [
          {
            type: 'radio',
            name: 'document_type',
            validation: 'required|in:cpf,cnpj',
            validationName: 'Tipo de Documento',
            class: 'col-xl-3',
            options: {
              cpf: 'Pessoa Física',
              cnpj: 'Pessoa Jurídica'
            }
          },
          {
            // eslint-disable-next-line camelcase
            visible: ({ document_type }) => document_type === 'cpf',
            type: 'text',
            name: 'name',
            class: 'col-xl-9',
            label: 'Seu nome completo',
            placehoder: 'Ex: Fulano da Silva',
            validation: 'required|min:5,length',
            validationName: 'Nome completo'
          },
          {
            // eslint-disable-next-line camelcase
            visible: ({ document_type }) => document_type === 'cnpj',
            type: 'text',
            name: 'name',
            class: 'col-xl-9',
            label: 'Razão Social',
            validation: 'required|min:5,length',
            validationName: 'Nome completo'
          },
          {
            type: 'text',
            name: 'zip_code',
            class: 'col-lg-3',
            label: 'CEP',
            validation: 'required|min:9,length|max:9,length',
            validationName: 'CEP',
            debounce: 500,
            vMask: '#####-###',
            '@input': 'input-zip-code'
          },
          {
            type: 'text',
            name: 'street',
            class: 'col-lg-6',
            label: 'Rua',
            validation: 'required|min:5,length',
            validationName: 'Rua'
          },
          {
            type: 'text',
            name: 'street_number',
            class: 'col-lg-3',
            label: 'Número',
            validationName: 'Número'
          },
          {
            type: 'text',
            name: 'city',
            class: 'col-lg-5',
            label: 'Cidade',
            validation: 'required|min:3,length',
            validationName: 'Cidade'
          },
          {
            type: 'text',
            name: 'neighborhood',
            class: 'col-lg-4',
            label: 'Bairro',
            validation: 'required|min:3,length',
            validationName: 'Bairro'
          },
          {
            type: 'select',
            name: 'state',
            class: 'col-lg-3',
            label: 'Estado',
            validation: 'required|min:2,length',
            validationName: 'Estado',
            options: statesOptions
          },
          {
            // eslint-disable-next-line camelcase
            visible: ({ document_type }) => document_type === 'cpf',
            type: 'text',
            name: 'document',
            class: 'col-lg-6',
            label: 'CPF',
            validation: 'required|max:14,length|min:14,length',
            validationName: 'CPF',
            vMask: '###.###.###-##'
          },
          {
            // eslint-disable-next-line camelcase
            visible: ({ document_type }) => document_type === 'cnpj',
            type: 'text',
            name: 'document',
            class: 'col-lg-6',
            label: 'CNPJ',
            validation: 'required|max:18,length|min:18,length',
            validationName: 'CPF',
            vMask: '##.###.###/####-##'
          },
          {
            type: 'text',
            name: 'phone',
            class: 'col-lg-6',
            label: 'Telefone',
            validation: 'required',
            validationName: 'Telefone',
            vMask: '(##) ####-#####'
          }
        ]
      }
    ]
  }
]
