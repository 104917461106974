<template>
  <div class="v-credit-card">
    <form @input="$emit('update:validation', { hasErrors: !allValid })">
      <div class="form-row">
        <div class="col-md-7">
          <div class="form-group d-flex">
            <div class="credit-card-brand mr-2">
              <span :class="brand"></span>
            </div>
            <input
              name="number"
              class="form-control credit-card-input"
              placeholder="Número do cartão"
              v-mask="pattern"
              v-model="card.number"
              :class="{ 'is-valid': isValid.number.isValid, 'is-invalid': !isValid.number.isPotentiallyValid }"
              required>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <input
              name="expiration"
              class="form-control credit-card-input"
              placeholder="MM/AA"
              v-mask="'##/##'"
              v-model="card.expiration"
              :class="{ 'is-valid': isValid.expiration.isValid, 'is-invalid': !isValid.expiration.isPotentiallyValid }"
              required>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <input
              name="cvv"
              class="form-control credit-card-input"
              placeholder="CVV"
              v-mask="code.pattern"
              v-model="card.cvv"
              :class="{ 'is-valid': isValid.cvv.isValid, 'is-invalid': !isValid.cvv.isPotentiallyValid }"
              required>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <input
              name="holder_name"
              class="form-control credit-card-input"
              placeholder="Nome no Cartão"
              v-model="card.holder_name"
              min="3"
              @blur="holderNameDirty = true"
              :class="{ 'is-valid': isValid.holder_name, 'is-invalid': !isValid.holder_name && holderNameDirty }"
              required>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import valid from 'card-validator'

const defaultPattern = '#### #### #### ####'
const cardPatterns = {
  default: defaultPattern,
  visa: defaultPattern,
  mastercard: defaultPattern,
  discover: defaultPattern,
  jcb: defaultPattern,
  unionpay: defaultPattern,
  'american-express': '#### ###### #####',
  'diners-club': '#### #### #### ##'
}

const cardBrands = {
  default: 'fal fa-credit-card',
  visa: 'fab fa-cc-visa',
  mastercard: 'fab fa-cc-mastercard',
  discover: 'fab fa-cc-discover',
  jcb: 'fab fa-cc-jcb',
  'american-express': 'fab fa-cc-amex',
  'diners-club': 'fab fa-cc-diners-club'
}

export default {
  name: 'VCreditCard',
  directives: { mask },
  props: {
    card: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      cardPatterns,
      holderNameDirty: false
    }
  },
  mounted () {
    valid.creditCardType.addCard({
      niceType: 'Banescard',
      type: 'banescard',
      patterns: [603182],
      gaps: [4, 8, 12],
      lengths: [16],
      code: {
        name: 'CVV',
        size: 3
      }
    })
  },
  computed: {
    pattern () {
      const card = this.isValid.number.card
      if (card && card.type) {
        // eslint-disable-next-line no-prototype-builtins
        return cardPatterns.hasOwnProperty(card.type) ? cardPatterns[card.type] : cardPatterns.default
      }
      return cardPatterns.default
    },
    allValid () {
      return this.isValid.number.isValid &&
        this.isValid.expiration.isValid &&
        // this.card.expiration.length === 7 &&
        this.isValid.cvv.isValid &&
        this.isValid.holder_name
    },
    isValid () {
      const number = valid.number(this.card.number)
      const size = number.card ? number.card.code.size : 3
      return {
        number,
        expiration: valid.expirationDate(this.card.expiration),
        cvv: valid.cvv(this.card.cvv, size),
        holder_name: this.card.holder_name.length >= 3
      }
    },
    brand () {
      const brand = this.isValid.number.card ? this.isValid.number.card.type : ''
      // eslint-disable-next-line no-prototype-builtins
      return cardBrands.hasOwnProperty(brand) ? cardBrands[brand] : cardBrands.default
    },
    code () {
      const size = this.isValid.number.card ? this.isValid.number.card.code.size : 3
      return {
        label: this.isValid.number.card ? this.isValid.number.card.code.name : 'CVV',
        size,
        pattern: Array.from(Array(size), (_, i) => '#').join('')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.credit-card-brand {
  min-width: 42px;
  height: auto;
  span {
    font-size: 38px;
    opacity: .3;
  }
}
</style>
