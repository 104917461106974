<template>
  <button @click="click" :disabled="disabled" class="ml-auto btn btn-default v-loading-button">
    <span v-if="!submitting">{{ label }}</span>
    <span v-else>{{ loadingText }}</span>
    <div v-if="submitting" class="spinner-border spinner-border-sm ml-2" role="status"></div>
  </button>
</template>

<script>
export default {
  name: 'VLoadingButton',
  props: {
    click: {
      type: Function,
      default: () => {}
    },
    disabled: Boolean,
    submitting: Boolean,
    label: {
      type: String,
      default: 'Enviar'
    },
    loadingText: {
      type: String,
      default: 'Carregando'
    }
  }
}
</script>
