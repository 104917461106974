import Vue from 'vue'
import FormulateInputText from '@/common/formulate/plugins/v-mask/FormulateInputText'
import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask)

export default function (formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateInputText
    }
  })
}
