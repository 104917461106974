<template>
  <div
    :class="context.classes.element"
    :data-type="context.type"
  >
    <FormulateSlot
      name="prefix"
      :context="context"
    >
      <component
        :is="context.slotComponents.prefix"
        v-if="context.slotComponents.prefix"
        :context="context"
      />
    </FormulateSlot>
    <input
      v-model="context.model"
      type="email"
      v-bind="attributes"
      @blur="context.blurHandler"
      v-on="$listeners"
    >
    <div v-if="suggestion" class="pt-1">
      {{ suggestionText }}
      <div class="d-inline-block mailcheck-suggestion-click" @click="setSuggestion">
        <span class="text-info">{{ suggestion.address }}@<strong>{{ suggestion.domain }}</strong></span>
      </div>?
    </div>
    <FormulateSlot
      name="suffix"
      :context="context"
    >
      <component
        :is="context.slotComponents.suffix"
        v-if="context.slotComponents.suffix"
        :context="context"
      />
    </FormulateSlot>
  </div>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'
import Mailcheck from 'mailcheck'

const domains = ['gmail.com', 'aol.com', 'hotmail.com', 'hotmail.com.br', 'alem.com.br', 'conceptho.com']
const secondLevelDomains = ['hotmail', 'gmail', 'uol', 'aol', 'outlook', 'live', 'alem', 'conceptho']
const topLevelDomains = ['com', 'net', 'org', 'br']

export default {
  name: 'FormulateMailCheck',
  mixins: [FormulateInputMixin],
  props: {
    suggestionText: {
      type: String,
      default: 'Você quis dizer'
    }
  },
  data () {
    return {
      suggestion: null
    }
  },
  methods: {
    check () {
      Mailcheck.run({
        domains: domains,
        secondLevelDomains: secondLevelDomains,
        topLevelDomains: topLevelDomains,
        email: this.model,
        suggested: suggestion => {
          this.suggestion = suggestion
        },
        empty: () => {
          this.suggestion = null
        }
      })
    },
    setSuggestion () {
      this.context.model = this.suggestion.full
    }
  },
  computed: {
    model () {
      return this.context.model
    }
  },
  watch: {
    model () {
      this.check()
    }
  }
}
</script>

<style scoped>
.mailcheck-suggestion-click {
  transition: opacity .12s linear;
}
.mailcheck-suggestion-click:hover {
  cursor: pointer;
  opacity: .7;
}
</style>
