<template>
  <div class="checkout-flow d-flex flex-md-row flex-column rounded-lg">
    <Loading :active="$apollo.loading" :is-full-page="false" />
    <div class="plan-container custom-scrollbar">
      <collapse-transition>
        <VCustomerSummary
          v-if="Me && user"
          :user="user"
          class="py-3 px-4 checkout-customer-summary"
        >
          <a href="#" @click.prevent="confirmLogout = !confirmLogout" class="text-white">
            <small>Deseja sair?</small>
          </a>
          <collapse-transition>
            <div v-if="confirmLogout">
              <small>
                Tem certeza?
                <button @click="clearCheckoutData" class="btn btn-outline-warning btn-sm py-0 mx-2">Sim</button>
                <button @click="confirmLogout = !confirmLogout" class="btn btn-outline-light btn-sm py-0">Não</button>
              </small>
            </div>
          </collapse-transition>
        </VCustomerSummary>
      </collapse-transition>
      <VPlanCard v-if="plan" :plan="plan" :hide-image="true" :always-collaspable="!!Me"/>
    </div>
    <div class="steps-container d-flex flex-column">
      <VStepsIndicator :steps="steps"/>
      <div class="steps-wrapper custom-scrollbar">
        <VRegister
          v-if="step === 'signup'"
          @submit="signup"
          :values.sync="values"
          :submitting="loaders.signup"
        />
        <VPayment
          v-if="step === 'payment'"
          @submit="pay"
          :user="user"
          :values.sync="values"
          :submitting="loaders.payment"
        />
        <VFinalizedCheckout
          :callback="signOut"
          :subscription="subscription"
          :user="user"
          v-if="step === 'finalized'"
        >
          <button
            v-if="['refused', 'failed'].includes(subscriptionStatus)"
            @click="step = 'payment'"
            class="btn btn-primary">
            voltar
          </button>
        </VFinalizedCheckout>
      </div>
    </div>
  </div>
</template>

<script>
import VPlanCard from '@/modules/checkout/components/VPlanCard'
import VStepsIndicator from '@/common/components/VStepsIndicator'
import VRegister from '@/modules/checkout/components/forms/VRegister'
import VPayment from '@/modules/checkout/components/VPayment'
import VFinalizedCheckout from '@/modules/checkout/components/VFinalizedCheckout'
import { SIGNUP, INSERT_BILLING_INFO, INSERT_SUBSCRIPTION, INSERT_CREDIT_CARD, UPDATE_BILLING_INFO } from '@/graphql/mutations'
import { GET_PLAN } from '@/graphql/queries'
import _pick from 'lodash/pick'
import { SignupInput, BillingInfoInput, SubscriptionInput, CardInput, SetBillingInfoInput } from '@/graphql/inputProperties'
import { values } from '@/modules/checkout/form-schemas/user'
import auth from '@/common/mixins/auth'
import graphqlErrors from '@/common/mixins/graphql-errors'
import creditCard from '@/common/mixins/credit-card'
import { updateUserValuesByCache } from '@/modules/checkout/helpers'
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import VCustomerSummary from '@/modules/checkout/components/VCustomerSummary'
import Loading from 'vue-loading-overlay'

const initialSteps = [
  { id: 'signup', title: 'cadastro', position: 1 },
  { id: 'payment', title: 'pagamento', position: 2 },
  { id: 'finalized', title: 'finalizado', position: 3 }
]

export default {
  name: 'CheckoutFlow',
  components: { VCustomerSummary, VFinalizedCheckout, VPayment, VRegister, VStepsIndicator, VPlanCard, CollapseTransition, Loading },
  mixins: [auth, graphqlErrors, creditCard],
  data () {
    return {
      confirmLogout: false,
      values,
      step: 'signup',
      loadedByCache: false,
      loaders: { signup: false, payment: false },
      billingInfo: null,
      subscription: null,
      card: null,
      cardHash: null
    }
  },
  created () {
    if (this.logged) this.step = 'payment'
  },
  methods: {
    async signup () {
      this.loaders.signup = true
      this.loadedByCache = true

      try {
        const { data: { signup } } = await this.$apollo.mutate({
          mutation: SIGNUP,
          variables: {
            data: _pick(this.values.user[0], SignupInput)
          }
        })
        await this.signInWithUser(signup)
        this.step = 'payment'
      } catch ({ message }) {
        this.loaders.signup = false
        this.setErrors([{ message }])
        throw Error()
      }
    },
    async createBillingInfo () {
      const data = {
        ...this.values.billing_info[0],
        external_id: this.user.customers[0].id,
        email: this.user.email || 'placeholder@conceptho.com',
        ...this.formatPhone(this.values.billing_info[0].phone)
      }

      try {
        // eslint-disable-next-line camelcase
        const { data: { insert_billing_info } } = await this.$apollo.mutate({
          mutation: INSERT_BILLING_INFO,
          variables: {
            data: _pick(data, BillingInfoInput)
          }
        })

        // eslint-disable-next-line camelcase
        this.billingInfo = insert_billing_info
      } catch (e) {
        this.loaders.payment = false
        e.customErrors = [
          { message: 'Erro ao salvar o endereço de cobrança.' },
          { message: 'Verifique os dados inseridos, como CNPJ ou CPF e tente novamente.' }
        ]
        throw e
      }
    },
    async updateBillingInfo () {
      // eslint-disable-next-line camelcase
      const { id, ...billingInfo } = this.billingInfo

      const data = {
        ...billingInfo,
        card_id: this.values.billing_info[0].payment_method === 'card' ? this.card.id : null,
        payment_method: this.values.billing_info[0].payment_method,
        ...this.formatPhone(this.values.billing_info[0].phone)
      }

      try {
        // eslint-disable-next-line camelcase
        const { data: { update_billing_info } } = await this.$apollo.mutate({
          mutation: UPDATE_BILLING_INFO,
          variables: {
            id: id,
            data: _pick(data, SetBillingInfoInput)
          }
        })

        // eslint-disable-next-line camelcase
        this.billingInfo = update_billing_info
      } catch (e) {
        this.loaders.payment = false
        e.customErrors = [
          { message: 'Erro ao salvar o endereço de cobrança.' },
          { message: 'Verifique os dados inseridos, como CNPJ ou CPF e tente novamente.' }
        ]
        throw e
      }
    },
    async createHash () {
      try {
        const requestData = {
          holder_name: this.values.card.holder_name,
          expiration_month: this.values.card.expiration.split('/')[0],
          expiration_year: this.values.card.expiration.split('/')[1],
          card_number: this.values.card.number.replace(/ /g, ''),
          security_code: this.values.card.cvv
        }

        const { id } = await this.tokenization(requestData)
        this.cardHash = id
      } catch (e) {
        e.customErrors = [
          { message: 'Erro ao usar o cartão de crédito.' },
          { message: 'Verifique os dados do cartão ou escolha um método diferente.' }
        ]
        throw e
      }
    },
    async createCard () {
      const data = {
        billing_info_id: this.billingInfo.id,
        card_hash: this.cardHash,
        external_id: this.user.customers[0].id
      }

      try {
        const { data: { insert_card: card } } = await this.$apollo.mutate({
          mutation: INSERT_CREDIT_CARD,
          variables: {
            data: _pick(data, CardInput)
          }
        })

        this.card = card
        this.values.billing_info[0].card_id = card.id
      } catch (e) {
        this.loaders.payment = false
        e.customErrors = [
          { message: 'Erro ao processar a sua assinatura.' },
          { message: 'Verifique os dados inseridos, e caso o erro continue, tente novamente mais tarde.' }
        ]
        throw e
      }
    },
    async createSubscription () {
      const data = {
        billing_info_id: this.billingInfo.id,
        external_id: this.user.customers[0].id,
        plan_id: this.plan.id
      }

      try {
        const { data: { insert_subscription: subscription } } = await this.$apollo.mutate({
          mutation: INSERT_SUBSCRIPTION,
          variables: {
            data: _pick(data, SubscriptionInput)
          }
        })

        this.subscription = subscription
      } catch (e) {
        this.loaders.payment = false
        e.customErrors = [
          { message: 'Erro ao processar a sua assinatura.' },
          { message: 'Verifique os dados inseridos, e caso o erro continue, tente novamente mais tarde.' }
        ]
        throw e
      }
    },
    async pay () {
      this.loaders.payment = true

      try {
        if (!this.billingInfo) await this.createBillingInfo()

        if (this.values.billing_info[0].payment_method === 'card') {
          await this.createHash()
          await this.createCard()
          await this.updateBillingInfo()
        } else if (this.billingInfo.id) {
          await this.updateBillingInfo()
        }

        await this.createSubscription()
        this.step = 'finalized'
      } catch (e) {
        if (e.customErrors && e.customErrors.length) {
          this.setErrors(e.customErrors)
        } else {
          this.setErrors([{ message: e.message }])
        }
      }

      this.loaders.payment = false
    },
    async clearCheckoutData () {
      await this.signOut()
      this.step = 'signup'
      this.values = values
    },
    formatPhone (number) {
      const onlyNumbers = number.replace(/\D/g, '')
      return {
        country_code: '55',
        area_code: onlyNumbers.substring(0, 2),
        phone: onlyNumbers.substring(2, 20)
      }
    }
  },
  apollo: {
    plan: {
      query: GET_PLAN,
      variables () {
        return {
          id: parseInt(this.$route.params.plan_id)
        }
      }
    }
  },
  computed: {
    steps () {
      return initialSteps.map(s => {
        s.active = s.id === this.step
        return s
      })
    },
    subscriptionStatus () {
      return this.subscription
        ? this.subscription.orders[0].invoices[0].status
        : 'none'
    }
  },
  watch: {
    user (val) {
      if (val && !this.loadedByCache) {
        updateUserValuesByCache(this, this.values, val)
        this.loadedByCache = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checkout-flow {
  overflow: hidden;
  @include media-breakpoint-up(md) {
    height: 90vh;
  }
}
.plan-container {
  background-color: $primary;
  @include media-breakpoint-up(md) {
    max-width: 350px;
    overflow-y: auto;
    //.v-plan-card {
    //  min-height: 100%;
    //}
  }
}
.steps-container {
  position: relative;
  width: 100%;
}
.steps-wrapper {
  background-color: transparentize(#fff, .3);
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

.checkout-customer-summary {
  background-color: transparentize($white, .9);
  min-height: 83px;
  color: #fff !important;
}
</style>
