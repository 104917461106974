export const SignupInput = [
  'birthdate',
  'business_name',
  'document',
  'document_type',
  'email',
  'gender',
  'name',
  'password',
  'phone',
  'phone_type',
  'username'
]

export const BillingInfoInput = [
  'area_code',
  'billing_account_id',
  'card_id',
  'city',
  'complement',
  'country_code',
  'country_id',
  'document',
  'document_type',
  'email',
  'external_id',
  'gateway_account_id',
  'invoice_advance_days',
  'name',
  'neighborhood',
  'payment_method',
  'phone',
  'provider_id',
  'state',
  'street',
  'street_number',
  'tax_document_in_advance',
  'zip_code'
]

export const SetBillingInfoInput = [
  'area_code',
  'card_id',
  'city',
  'complement',
  'country_code',
  'country_id',
  'document',
  'document_type',
  'email',
  'external_id',
  'invoice_advance_days',
  'name',
  'neighborhood',
  'payment_method',
  'phone',
  'state',
  'street',
  'street_number',
  'tax_document_in_advance',
  'zip_code'
]

export const SubscriptionInput = [
  'billing_account_id',
  'billing_info_id',
  'external_id',
  'next_credit_date',
  'next_invoice_date',
  'next_plan_id',
  'pending_amount',
  'plan_id',
  'previous_subscription_id',
  'remaining_cycles',
  'renew',
  'status'
]

export const CardInput = [
  'billing_account_id',
  'billing_info_id',
  'card_hash',
  'external_id'
]

export const LoginInput = [
  'username',
  'password'
]

export const SetMeInput = [
  'id',
  'name',
  'avatar',
  'refresh_token',
  'token',
  'exp',
  'roles'
]
