<template>
  <div class="v-register custom-scrollbar">
    <div class="register-wrapper">
      <FormulateForm
        v-model="computedValues"
        :schema="computedUserSchema"
        :keep-model-data="true"
        @submit="submit"
      >
        <div class="d-flex">
          <VLoadingButton
            type="submit"
            class="ml-auto btn-primary"
            :submitting="submitting"
            :disabled="submitting"
          />
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { schema as userSchema } from '@/modules/checkout/form-schemas/user'
import { conditional } from '@/common/formulate/helpers'
import props from '@/common/mixins/props'
import '@/common/formulate'
import VLoadingButton from '@/common/components/VLoadingButton'

export default {
  name: 'VRegister',
  components: { VLoadingButton },
  props: {
    submitting: {
      type: Boolean,
      default: false
    }
  },
  mixins: [props],
  data () {
    return {
      userSchema
    }
  },
  methods: {
    submit () {
      this.$emit('submit')
    }
  },
  computed: {
    computedUserSchema () {
      return conditional(this.userSchema, this.values)
    }
  }
}
</script>

<style lang="scss">
label {
  color: $gray-600;
}
input {
  background-color: transparentize(#fff, .5);
}
</style>
