<template>
  <div class="v-payment">
    <div>
      <h5 class="text-muted">Endereço de Cobrança</h5>
      <hr>
      <VBillingInfo
        :validation.sync="validation.billingInfo"
        :hidden-submit="true"
        :values.sync="computedValues"
      />
    </div>
    <div class="mb-3">
      <h5 class="text-muted mt-3">Forma de Pagamento</h5>
      <hr>
      <VRadio v-if="values.billing_info" :options="paymentTypes" :model.sync="values.billing_info[0].payment_method"/>
    </div>
    <VCreditCard
      :validation.sync="validation.creditCard"
      :card.sync="values.card"
      v-if="values.billing_info && values.billing_info[0].payment_method === 'card'"
    />
    <div v-if="values.billing_info && values.billing_info[0].payment_method === 'bank_slip'">
      <p>
        Seu cadastro será efetuado e te enviaremos o link para baixar o boleto.
      </p>
    </div>
    <div class="d-flex mt-3">
      <VLoadingButton
        id="payment-submit"
        type="submit"
        class="ml-auto btn-primary"
        :click="submit"
        :submitting="submitting"
        :disabled="submitting || !canNext"
      />
    </div>
  </div>
</template>

<script>
import VBillingInfo from '@/modules/checkout/components/forms/VBillingInfo'
import VCreditCard from '@/modules/checkout/components/forms/VCreditCard'
import VRadio from '@/common/components/VRadio'
import props from '@/common/mixins/props'
import VLoadingButton from '@/common/components/VLoadingButton'

export default {
  name: 'VPayment',
  components: { VLoadingButton, VRadio, VCreditCard, VBillingInfo },
  mixins: [props],
  props: {
    user: Object,
    submitting: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paymentTypes: {
        card: { label: 'Cartão de Crédito', disabled: false, value: 'card' },
        bank_slip: { label: 'Boleto', disabled: false, value: 'bank_slip' }
      },
      validation: {
        billingInfo: { hasErrors: true },
        creditCard: { hasErrors: true }
      },
      prefilled: false
    }
  },
  mounted () {
    this.prefill()
  },
  methods: {
    submit () {
      this.$emit('submit')
    },
    prefill () {
      this.mutateValues({
        billing_info: [
          {
            ...this.values.billing_info[0],
            name: this.values.user[0].name,
            document: this.values.user[0].document,
            phone: this.values.user[0].phone,
            document_type: this.values.user[0].document_type
          }
        ]
      })
    }
  },
  computed: {
    billingInfos () {
      return this.user &&
        this.user.customers.length &&
        this.user.customers[0].billing_account.length &&
        this.user.customers[0].billing_account[0].billing_infos.length
        ? this.user.customers[0].billing_account[0].billing_infos : []
    },
    canNext () {
      return !this.validation.billingInfo.hasErrors &&
        (this.values.billing_info[0].payment_method === 'card' ? !this.validation.creditCard.hasErrors : true)
    }
  }
}
</script>
