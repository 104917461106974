import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'
import '../../../node_modules/@braid/vue-formulate/themes/snow/snow.scss'
import './style.scss'
import { pt } from '@braid/vue-formulate-i18n'
import MailCheckInput from '@/common/formulate/plugins/MailCheckInput'
import VMask from '@/common/formulate/plugins/VMask'

Vue.use(VueFormulate, {
  plugins: [
    pt,
    MailCheckInput,
    VMask
  ],
  locale: 'pt',
  classes: {
    element: ['custom-element-width']
  }
})
