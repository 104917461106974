import { LOGIN, SET_ME } from '@/graphql/mutations'
import { ME, USER } from '@/graphql/queries'
import { isLogged, onLogin, onLogout } from '@/common/utils/auth'

export default {
  name: 'auth',
  data () {
    return {
      user: null
    }
  },
  apollo: {
    Me: {
      query: ME,
      async result ({ data }) {
        if (data) await this.getUser()
      }
    }
  },
  methods: {
    async signInWithUser (user) {
      await this.signOut()

      const { data: { setMe: me } } = await this.$apollo.mutate({
        mutation: SET_ME,
        variables: user
      })

      await onLogin({ apollo: this.$apollo, data: me })

      await this.getUser()
      return user
    },
    async signIn (username, password) {
      await this.signOut()

      const { data: { login } } = await this.$apollo.mutate({
        mutation: LOGIN,
        variables: { data: { username, password } }
      })

      const { data: { setMe: me } } = await this.$apollo.mutate({
        mutation: SET_ME,
        variables: login
      })

      await onLogin({ apollo: this.$apollo, data: me })
      return me
    },
    async signOut () {
      await this.$apollo.getClient().cache.reset()
      this.Me = null
      this.user = null
      return onLogout(this.$apollo)
    },
    async getUser () {
      const { data: { user_by_pk: user } } = await this.$apollo.query({
        query: USER,
        variables: {
          id: parseInt(this.Me.id)
        }
      })
      this.user = user
    }
  },
  computed: {
    logged () {
      return isLogged()
    }
  }
}
