const divider = {
  component: 'div',
  class: 'row',
  children: [
    {
      component: 'div',
      class: 'col-12',
      children: [
        {
          component: 'div', class: 'divider'
        }
      ]
    }
  ]
}

const submit = {
  name: 'submit',
  class: 'ml-auto',
  type: 'submit',
  disabled: true,
  label: 'Avançar'
}

const statesOptions = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins'
}

export {
  divider,
  submit,
  statesOptions
}
