export const updateUserValuesByCache = (vue, values, user) => {
  const customer = user.customers.length ? user.customers[0] : {
    document_type: 'cpf'
  }

  const phone = customer.contacts ? customer.contacts[0] : {
    phone_type: 'mobile',
    phone: ''
  }

  const newUser = {
    name: user.name,
    document_type: customer.document_type.toLowerCase(),
    email: user.email,
    email_confirm: user.email,
    username: user.username,
    document: customer.document_value,
    birthdate: customer.birthdate,
    phone_type: phone.type,
    phone: phone.value,
    gender: customer.gender,
    business_name: customer.business_name
  }
  vue.$set(values.user, 0, newUser)
}
