<template>
  <div class="checkout-view d-flex justify-content-center align-items-center">
    <div class="container">
      <CheckoutFlow class="shadow-lg"/>
    </div>
  </div>
</template>

<script>
import CheckoutFlow from '@/modules/checkout/components/CheckoutFlow'

export default {
  name: 'Checkout',
  components: {
    CheckoutFlow
  }
}
</script>

<style lang="scss" scoped>
.checkout-view {
  position: relative;
  @include media-breakpoint-up(md) {
    height: 100vh;
  }
  @include media-breakpoint-down(md) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &:before {
    position: absolute;
    content: '';
    background-image: url('../assets/images/background-paper-01.jpg');
    background-size: cover;
    background-position: center center;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .2;
    z-index: -1;
  }
}
</style>
