<template>
  <div class="v-finalized-checkout">
    <div class="finalized-success" v-if="['paid', 'authorized'].includes(status)">
      <h3 class="text-success">Parabéns!</h3>
      <h4>Sua assinatura foi evetuada com sucesso</h4>
    </div>

    <div class="finalized-processing" v-else-if="['processing'].includes(status)">
      <h3 class="text-success">Parabéns!</h3>
      <h4>Sua assinatura foi evetuada e estamos processando o seu pagamento!</h4>
    </div>

    <div class="finalized-refused" v-else-if="['refused', 'failed'].includes(status)">
      <h3 class="text-danger">Ops!</h3>
      <h4>Não foi possível processar o seu pagamento.</h4>
      <p>
        Volte para a etapa anterior e verifique suas informações de pagamento. Se necessário, escolha uma forma de pagamento diferente.
      </p>
      <p>
        Caso o erro persista, aguarde alguns instantes para tentar novamente.
      </p>
    </div>

    <div class="finalized-waiting_payment" v-else-if="['waiting_payment'].includes(status)">
      <h3 class="text-success">Parabéns!</h3>
      <h4>Sua assinatura foi efetuada, mas estamos aguardando o seu pagamento.</h4>
    </div>

    <div class="finalized-error" v-else>
      <h3 class="text-danger">Ops!</h3>
      <h4>Houve alguma falha ao processar o seu pagamento</h4>
      <p>Tente novamente mais tarde.</p>
    </div>
    <pre>
      {{ subscription }}
    </pre>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VFinalizedCheckout',
  props: {
    subscription: Object,
    callback: {
      type: Function,
      default: () => {}
    }
  },
  mounted () {
    if (['paid', 'authorized', 'processing', 'waiting_payment'].includes(this.status)) this.callback()
  },
  computed: {
    status () {
      return this.subscription.orders[0].invoices[0].status
    }
  }
}
</script>
