<template>
  <div class="v-customer-summary">
    <h6 class="mb-0 font-weight-normal">Olá, {{ user.name }}!</h6>
    <div>
      <small>Você está logado como <strong>{{ user.username }}</strong></small>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VCustomerSummary',
  props: {
    user: Object
  }
}
</script>
