<template>
  <div
    :class="context.classes.element"
    :data-type="context.type"
  >
    <FormulateSlot
      name="prefix"
      :context="context"
    >
      <component
        :is="context.slotComponents.prefix"
        v-if="context.slotComponents.prefix"
        :context="context"
      />
    </FormulateSlot>
    <input
      v-if="mask"
      v-mask="mask"
      v-model="context.model"
      :type="type"
      v-bind="attributes"
      @blur="context.blurHandler"
      v-on="$listeners"
    >
    <input
      v-else
      v-model="context.model"
      :type="type"
      v-bind="attributes"
      @blur="context.blurHandler"
      v-on="$listeners"
    >
    <FormulateSlot
      name="suffix"
      :context="context"
    >
      <component
        :is="context.slotComponents.suffix"
        v-if="context.slotComponents.suffix"
        :context="context"
      />
    </FormulateSlot>
  </div>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'

export default {
  name: 'FormulateInputText',
  mixins: [FormulateInputMixin],
  computed: {
    mask () {
      return this.attributes.vMask ? this.attributes.vMask : false
    }
  }
}
</script>
