<template>
  <div class="v-billing-info">
    <FormulateForm
      v-model="computedValues"
      :schema="computedUserSchema"
      :keep-model-data="true"
      @validation="$emit('update:validation', $event)"
      @input-zip-code="getAddressByCep"
      @submit="submit">
      <div class="d-flex">
        <FormulateInput
          v-if="!hiddenSubmit"
          type="submit"
          name="Enviar"
          class="ml-auto"
        />
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { schema as billingInfoSchema } from '@/modules/checkout/form-schemas/billing-info'
import { conditional } from '@/common/formulate/helpers'
import '@/common/formulate'
import { getAddressByCep } from '@/common/helpers'
import props from '@/common/mixins/props'

export default {
  name: 'VBillingInfo',
  props: {
    hiddenSubmit: {
      type: Boolean,
      default: false
    }
  },
  mixins: [props],
  data () {
    return {
      billingInfoSchema
    }
  },
  methods: {
    submit () {
      this.$emit('submit')
    },
    async getAddressByCep (val) {
      if (val && val.replace('-', '').length === 8) {
        const { cep, ...result } = await getAddressByCep(val)
        this.mutateValues({
          billing_info: [
            { ...this.values.billing_info[0], ...result }
          ]
        })
      }
    }
  },
  computed: {
    computedUserSchema () {
      return conditional(this.billingInfoSchema, this.values)
    }
  }
}
</script>
