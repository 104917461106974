export default {
  props: {
    values: {
      type: Object
    }
  },
  methods: {
    mutateValues (val) {
      this.$emit('update:values', { ...this.values, ...val })
    }
  },
  computed: {
    computedValues: {
      get () {
        return this.values
      },
      set (val) {
        this.$emit('update:values', val)
      }
    }
  }
}
