import MailCheckInput from '@/common/formulate/custom-inputs/MailCheck'

export default function (formulateInstance) {
  formulateInstance.extend({
    components: {
      MailCheckInput
    },
    library: {
      mailcheck: {
        classification: 'text',
        component: 'MailCheckInput',
        slotProps: {
          component: ['suggestionText']
        }
      }
    }
  })
}
