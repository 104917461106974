import {
  divider
  // submit
} from '@/common/formulate/form-schemas'

export const values = {
  user: [
    { document_type: 'cpf' }
  ],
  billing_info: [
    { payment_method: 'card', document_type: 'cpf', country_id: 33, country_code: 'BR' }
  ],
  card: { number: '', cvv: '', expiration: '', holder_name: '' }
}

export const schema = [
  {
    type: 'group',
    repeatable: false,
    name: 'user',
    children: [
      {
        component: 'div',
        class: 'form-row',
        children: [
          {
            type: 'radio',
            name: 'document_type',
            label: 'Tipo de cadastro',
            class: 'col-xl-3',
            validation: 'required|in:cpf,cnpj',
            validationName: 'Tipo de cadastro',
            options: {
              cpf: 'Pessoa Física',
              cnpj: 'Pessoa Jurídica'
            }
          },
          {
            // eslint-disable-next-line camelcase
            visible: ({ document_type }) => document_type === 'cpf',
            type: 'text',
            name: 'name',
            class: 'col-xl-9',
            label: 'Seu nome completo',
            placehoder: 'Ex: Fulano da Silva',
            validation: 'required|min:5,length',
            validationName: 'Nome completo'
          },
          {
            // eslint-disable-next-line camelcase
            visible: ({ document_type }) => document_type === 'cnpj',
            type: 'text',
            name: 'name',
            class: 'col-xl-9',
            label: 'Nome do responsável',
            placehoder: 'Ex: Fulano da Silva',
            validation: 'required|min:5,length',
            validationName: 'Nome do responsável'
          },
          {
            type: 'mailcheck',
            suggestionText: 'Você quis dizer',
            name: 'email',
            class: 'col-lg-6',
            label: 'E-mail',
            placehoder: 'fulano@exemplo.com.br',
            // help: 'É através do seu e-mail que faremos a maioria da nossa comunicação',
            validation: 'required|email',
            validationName: 'E-mail'
          },
          {
            type: 'email',
            name: 'email_confirm',
            class: 'col-lg-6',
            label: 'Repita seu e-mail',
            placehoder: 'fulano@exemplo.com.br',
            validation: 'required|confirm:email',
            validationName: 'Confirmação de e-mail'
          },
          {
            type: 'text',
            name: 'username',
            class: 'col-lg-4',
            label: 'Seu nome de usuário',
            placehoder: '',
            help: 'Esse será seu login de acesso a plataforma',
            validation: 'required|min:5,length|max:30,length',
            validationName: 'Nome de usuário'
          },
          {
            type: 'password',
            name: 'password',
            class: 'col-lg-4',
            label: 'Sua senha',
            placehoder: '******',
            help: 'Escolha uma senha forte e anote-a em algum lugar',
            validation: 'required|min:6,length',
            validationName: 'Senha'
          },
          {
            type: 'password',
            name: 'password_confirm',
            class: 'col-lg-4',
            label: 'Confirme sua senha',
            placehoder: '******',
            validation: 'required|confirm:password',
            validationName: 'Senha'
          },
          {
            component: 'div',
            class: 'col-12 mb-4',
            children: [
              divider
            ]
          },
          {
            // eslint-disable-next-line camelcase
            visible: ({ document_type }) => document_type === 'cpf',
            type: 'text',
            name: 'document',
            class: 'col-lg-9',
            label: 'CPF',
            vMask: '###.###.###-##',
            validation: 'required|max:14,length|min:14,length',
            validationName: 'CPF'
          },
          {
            // eslint-disable-next-line camelcase
            visible: ({ document_type }) => document_type === 'cnpj',
            type: 'text',
            name: 'document',
            class: 'col-lg-4',
            label: 'CNPJ',
            validation: 'required|max:18,length|min:18,length',
            vMask: '##.###.###/####-##',
            validationName: 'CNPJ'
          },
          {
            // eslint-disable-next-line camelcase
            visible: ({ document_type }) => document_type === 'cnpj',
            type: 'text',
            name: 'business_name',
            class: 'col-lg-5',
            label: 'Razão Social',
            validation: 'required|min:5',
            validationName: 'CPF'
          },
          {
            type: 'date',
            name: 'birthdate',
            class: 'col-lg-3',
            label: 'Data de Nascimento',
            validation: 'required|date:YYYY-MM-DD',
            validationName: 'Data de Nascimento',
            validationMessages: {
              date: 'Informe uma data válida'
            }
          },
          {
            type: 'select',
            name: 'phone_type',
            class: 'col-lg-3',
            label: 'Tipo de telefone',
            validation: 'required|in:mobile,phone',
            validationName: 'Tipo de telefone',
            options: {
              mobile: 'Celular',
              phone: 'Fixo'
            }
          },
          {
            type: 'text',
            name: 'phone',
            class: 'col-lg-5',
            label: 'Telefone',
            help: 'Usaremos esse número para entrar em contato com você, quando necessário',
            vMask: '(##) ####-#####',
            validation: 'required',
            validationName: 'Telefone'
          },
          {
            type: 'select',
            name: 'gender',
            class: 'col-lg-4',
            label: 'Gênero',
            validation: 'required|in:male,female,other',
            validationName: 'Gênero',
            options: {
              male: 'Masculino',
              female: 'Feminino',
              other: 'Outro'
            }
          }
        ]
      }
    ]
  }
  // {
  //   component: 'div',
  //   class: 'd-flex',
  //   children: [
  //     submit
  //   ]
  // }
]
