import cepPromise from 'cep-promise'

const getAddressByCep = async cep => {
  const cepString = cep.replace('-', '')
  if (cep && cepString.length === 8) {
    try {
      return cepPromise(cepString, {
        providers: [
          'brasilapi', 'viacep'
        ]
      })
    } catch (e) {
      return { cep: '' }
    }
  }

  return { cep: '' }
}

export {
  getAddressByCep
}
