import axios from 'axios'

const GATEWAY_API = process.env.VUE_APP_GATEWAY_ZOOP_ENDPOINT
const MARKETPLACE_ID = process.env.VUE_APP_ZOOP_MARKETPLACE_ID
const GATEWAY_KEY = process.env.VUE_APP_GATEWAY_ZOOP_KEY

export default {
  methods: {
    async tokenization (data) {
      if (data.expiration_year.length === 2) data.expiration_year = `20${data.expiration_year}`
      const url = `${GATEWAY_API}/marketplaces/${MARKETPLACE_ID}/cards/tokens`

      const auth = {
        username: GATEWAY_KEY,
        password: ''
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      const tokenization = await axios.post(url, data, { auth, headers })

      return tokenization.data
    }
  }
}
