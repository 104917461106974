import Noty from 'noty'

export default {
  name: 'GraphqlErrors',
  data () {
    return {
      errors: []
    }
  },
  methods: {
    setErrors (errors) {
      this.errors = errors.map(e => {
        e.message = e.message.replace('GraphQL error: ', '')
        return e
      })
    }
  },
  watch: {
    errors (val) {
      if (val && val.length) {
        val.forEach(e => {
          new Noty({
            type: 'error',
            text: e.message,
            layout: 'topRight',
            timeout: 4000
          }).show()
        })
      }
    }
  }
}
