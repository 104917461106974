<template>
  <div class="v-radio">
    <div class="custom-control custom-radio custom-control-inline" v-for="{ value, disabled, label } in options" :key="value">
      <input
        :name="model"
        type="radio"
        class="custom-control-input"
        :value="value"
        :checked="model === value"
        @change="$emit('update:model', value)"
        :id="`${_uid}-${value}`"
        :disabled="disabled">
      <label class="custom-control-label" :for="`${_uid}-${value}`">
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VRadio',
  props: {
    options: {
      type: Object,
      required: true
    },
    model: {
      type: String,
      required: true
    }
  }
}
</script>
