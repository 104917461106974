<template>
  <div class="v-steps-indicator">
    <div class="d-flex pb-3 pt-4 align-items-center">
      <div
        class="step flex-fill"
        v-for="{ position, title } in steps"
        :key="position"
        :class="{ active: position <= activeStep.position, last: activeStep.position === position }">
        <div class="step-circle d-inline-block"></div>
        <div class="step-title small">{{ title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VStepsIndicator',
  props: {
    steps: Array // [{ title: 'foo', position: 1, active: true }]
  },
  computed: {
    activeStep () {
      return this.steps.find(s => s.active)
    }
  }
}
</script>

<style lang="scss" scoped>
$step-cirlce-width: 16px;
$step-default-color: #fff;
$step-active-color: $primary;
.v-steps-indicator {
  background-color: #ccc
}
.step {
  text-align: center;
  position: relative;
  color: $step-default-color;
  .step-title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.active {
    color: $step-active-color;
    .step-circle, &:before, &:after {
      background-color: $step-active-color;
    }
  }
  &.last {
    .step-circle {
      transform: scale(1.5);
    }
    &:after, &:before {
      top: calc(#{$step-cirlce-width} / 2);
    }
    &:after {
      background-color: $step-default-color;
    }
  }
  .step-circle {
    position: relative;
    width: $step-cirlce-width;
    height: $step-cirlce-width;
    border-radius: 50%;
    background-color: $step-default-color;
    z-index: 2;
  }
  &:before, &:after {
    position: absolute;
    content: '';
    top: calc(#{$step-cirlce-width} / 2);
    left: 0;
    width: 50%;
    height: 2px;
    background-color: $step-default-color;
    z-index: 1;
  }
  &:after {
    left: auto;
    right: 0;
    background-color: $step-default-color;
  }
  &:first-child {
    &:before {
      display: none;
    }
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
}
</style>
