const conditional = (schema, values) => {
  return schema.filter(s => {
    // eslint-disable-next-line no-prototype-builtins
    if (s.hasOwnProperty('visible')) {
      return s.visible(values)
    }
    return true
  }).map(({ children, name, ...s }, i) => {
    return {
      ...s,
      children: children ? conditional(children, (name && values[name]) ? values[name][i] : values) : null,
      name
    }
  })
}

export {
  conditional
}
